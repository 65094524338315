import React, { useState, useEffect } from "react";
import Link from "next/link";
import * as Text from "@/components/text";
import SvgLogo from "@/components/lib/SvgLogo";
import { SocialIcon } from 'react-social-icons/component';
import 'react-social-icons/whatsapp';
import 'react-social-icons/youtube';
import 'react-social-icons/tiktok';
import 'react-social-icons/facebook';
import 'react-social-icons/x';
import 'react-social-icons/instagram';
import 'react-social-icons/vimeo';
import 'react-social-icons/flickr';
import { useRouter } from "next/router";
import { localeTexts } from "@/utils/locale";

export default function Footer() {
  const router = useRouter();
  const [locale, setLocale] = useState("es-MX");
  const t = localeTexts[locale]?.footer || {};
  const commonT = localeTexts[locale]?.common || {};
    
  // Configuration for theme name prefixes
    const excludedPrefixes = {
      'es-MX': ['mex', 'línea'],
      'en-US': ['mex', 'line']
    };
        // Sync locale with URL and localStorage
      useEffect(() => {
        if (!router.isReady) return;
    
        const urlLocale = router.query.locale;
        const storedLocale = typeof window !== "undefined" 
          ? localStorage.getItem("amb-locale") 
          : null;
        const finalLocale = urlLocale || storedLocale || "es-MX";
    
        setLocale(finalLocale);
        localStorage.setItem("amb-locale", finalLocale);
      }, [router.isReady, router.query.locale]);
  
  

  return (
    <div className="grid w-full grid-cols-2 gap-5 md:grid-cols-4 lg:grid-cols-6">
      <div className="md:col-span-1">
        <Text.Overline className="mb-6 text-neutral-400 " as="h4">
          {t.forYou || "PARA TI"}
        </Text.Overline>
        <ul className="space-y-4">
          <li>
            <Link href={`/catalogo?locale=${locale}`} passHref>
               <Text.Link label={t.movies || "Películas"}></Text.Link>
            </Link>
          </li>
          <li>
           <Link href={`/calendario?locale=${locale}`} passHref>
              <Text.Link label={t.calendar || "Calendario"}></Text.Link>
            </Link>
          </li>
          <li>
             <Link href={`/prensa?locale=${locale}`} passHref>
              <Text.Link label={t.pressCenter || "Centro de prensa"}></Text.Link>
            </Link>
          </li>
        </ul>
      </div>
      <div className="md:col-span-1">
        <Text.Overline className="mb-6 text-neutral-400 " as="h4">
          {t.programs || "PROGRAMAS"}
        </Text.Overline>
        <ul className="space-y-4">
          <li>
           <Link href={`/iniciativa/gira?locale=${locale}`} passHref>
              <Text.Link label={t.tour || "Gira Ambulante"}></Text.Link>
            </Link>
          </li>
          <li>
              <Link href={`/ambulante-mas-alla?locale=${locale}`} passHref>
              <Text.Link label={t.beyond || "Ambulante Más Allá"}></Text.Link>
            </Link>
          </li>
          <li>
           <Link href={`/ambulante-presenta?locale=${locale}`} passHref>
              <Text.Link label={t.presents || "Ambulante Presenta"}></Text.Link>
            </Link>
          </li>
          <li>
             <Link href={`/ambulante-ediciones?locale=${locale}`} passHref>
              <Text.Link label={t.editions || "Ambulante Ediciones"}></Text.Link>
            </Link>
          </li>
        </ul>
      </div>
      <div className="md:col-span-1">
        <Text.Overline className="mb-6 text-neutral-400 " as="h4">
          {t.information || "INFORMACIÓN"}
        </Text.Overline>
        <ul className="space-y-4">
          <li>
             <Link href={`/equipo?locale=${locale}`} passHref>
              <Text.Link label={t.directory || "Directorio"}></Text.Link>
            </Link>
          </li>
          <li>
             <Link href={`/aviso-de-privacidad?locale=${locale}`} passHref>
              <Text.Link label={t.privacy || "Aviso de Privacidad"}></Text.Link>
            </Link>
          </li>
          <li>
            <Link href={`/contraloria-social?locale=${locale}`} passHref>
              <Text.Link label={t.socialControl || "Contraloría Social"}></Text.Link>
            </Link>
          </li>
          <li>
            <Link href={`/vacantes?locale=${locale}`} passHref>
              <Text.Link label={t.vacancies || "Vacantes"}></Text.Link>
            </Link>
          </li>
        </ul>
      </div>

      <div className="col-span-1 space-y-4 text-center text-neutral-900 md:col-span-4 lg:col-span-1 lg:text-left">
        <Text.Overline className="mb-6 text-neutral-400 " as="h4">
            {t.contact || "Contáctanos"}
        </Text.Overline>
        <div className="block gap-10 space-y-3">
          <div>
            <Link target="_top" href="mailto:hola@ambulante.org">

              <Text.Link size="lg" label="hola@ambulante.org"></Text.Link>
            </Link>
          </div>
          <div>
            <Link href={`/newsletter?locale=${locale}`}>
              <Text.Link size="lg" label={t.newsletter || "Suscríbete al newsletter"}></Text.Link>
            </Link>

          </div>
          <div>

            <Link href="tel:+525555115073">
              <Text.Link size="lg" label="+52 (55) 5511 5073"></Text.Link>
            </Link>
          </div>
          <div>

            <Link href="tel:+525543332019">
              <Text.Link size="lg" label="+52 (55) 4333 2019"></Text.Link>
            </Link>
          </div>
        </div>
      </div>

      <div className="col-span-2 space-y-4 text-center text-neutral-900 md:col-span-4 lg:col-span-2 lg:text-left">
        <div >

          <ul className="grid grid-cols-5 gap-2 md:flex">
            <li>

              <SocialIcon target="_blank" url="https://bit.ly/450Xxeb" network="whatsapp"
                style={{ width: 35, height: 35 }}
                bgColor="black" />
            </li>
            <li >

              <SocialIcon target="_blank" url="https://www.youtube.com/@ambulante" network="youtube"
                style={{ width: 35, height: 35 }}
                bgColor="black" />
            </li>
            <li>

              <SocialIcon target="_blank" url="https://www.tiktok.com/@ambulanteac/" network="tiktok"
                style={{ width: 35, height: 35 }}
                bgColor="black" />
            </li>
            <li>

              <SocialIcon target="_blank" url="https://www.facebook.com/AmbulanteAC" network="facebook"
                style={{ width: 35, height: 35 }}
                bgColor="black" />

            </li>
            <li>

              <SocialIcon target="_blank" url="https://x.com/Ambulante"
                style={{ width: 35, height: 35 }}
                bgColor="black" />

            </li>
            <li>
              <SocialIcon target="_blank" url="https://www.instagram.com/ambulanteac/" network="instagram"
                style={{ width: 35, height: 35 }}
                bgColor="black" />
            </li>
            <li>

              <SocialIcon target="_blank" url="https://vimeo.com/ambulanteonline" network="vimeo"
                style={{ width: 35, height: 35 }}
                bgColor="black" />
            </li>
            <li>

              <SocialIcon target="_blank" url="https://www.flickr.com/photos/festival_ambulante/"
                network="flickr"
                style={{ width: 35, height: 35 }}
                bgColor="black" />
            </li>
          </ul>
        </div>
        <div className="hidden lg:block">
          <SvgLogo className="w-[200px] " />
        </div>
        <div className="lg:hidden ">
          <div className="relative">
            <div
              className="absolute inset-0 flex items-center lg:hidden"
              aria-hidden="true"
            >
              <div className="w-full border-t border-neutral-300" />
            </div>
            <div className="relative flex justify-center">
              <span className="px-2 text-sm bg-white text-neutral-900">
                <SvgLogo className="w-[200px] " />
              </span>
            </div>
          </div>
        </div>
        <Text.Label size="lg" as="p">
          Zacatecas 142-A, Roma Norte, Cuauhtémoc, C.P. 06700, Ciudad de México
        </Text.Label>

        <div>
          <Text.Body size="lg" className="text-neutral-600">
             {t.copyright || "© 2024 Ambulante. Todos los derechos reservados"}
          </Text.Body>
        </div>
      </div>
    </div>
  );
}
