import { useState, useEffect } from "react";
import Script from "next/script";
import { useRouter } from "next/router";
import "../styles/globals.css";
import Head from "next/head";
import Footer from "@/components/navigation/Footer";
import Banner from "@/components/lib/Banner";
import SearchBar, { SearchContext } from "@/components/lib/SearchBar";
import "nprogress/nprogress.css";
import dynamic from 'next/dynamic';

import nProgress from "nprogress";
import FilterContextProvider from "stores/FilterContextProvider";
import IniciativaItemProvider from "stores/IniciativasContextProvider";
import GlobalContextProvider from "stores/GlobalContextProvider";


function MyApp({ Component, pageProps }) {
  const [display, setDisplay] = useState(false);
  const router = useRouter();
  const [locale, setLocale] = useState('es-MX');
  const getLayout = Component.getLayout || ((page) => page);

  useEffect(() => {
    // Prioritize: URL query > localStorage > default
    const urlLocale = router.query.locale;
    const storedLocale = typeof window !== 'undefined' 
      ? window.localStorage.getItem('amb-locale') 
      : null;

    const finalLocale = urlLocale || storedLocale || 'es-MX';

    // Update router query if not present
    if (!urlLocale) {
      router.replace(
        { 
          pathname: router.pathname, 
          query: { ...router.query, locale: finalLocale } 
        },
        undefined,
        { shallow: true }
      );
    }

    setLocale(finalLocale);

    // Store in localStorage
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('amb-locale', finalLocale);
    }
  }, [router.pathname]);

  return (
    <>
      <Head>
        <meta charSet="utf-8"></meta>
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="es_MX" />
        <meta property="og:type" content="website" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff"></meta>
      </Head>
      <Script
        strategy="afterInteractive"
        src="https://www.googletagmanager.com/gtag/js?id=G-LHRFZG60CX"
      />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-LHRFZG60CX', {
          page_path: window.location.pathname,
        });
      `,
        }}
      />
      <GlobalContextProvider>
        <FilterContextProvider>
          <IniciativaItemProvider>
            <SearchContext.Provider value={{ display, setDisplay }}>
              <div id="documentRoot" key="app" className="relative h-screen">
                <SearchBar />
                {getLayout(<Component {...pageProps} locale={router.query.locale || 'es-MX'} />)}
                <div className="mt-16 flex flex-row gap-2 sm:mx-4 md:mx-8 md:py-4 lg:mx-28 lg:py-5 xl:mx-auto xl:max-w-[1208px]">
                  <Footer locale={locale}/>
                </div>
                <div className="fixed bottom-0 z-50 w-full">
                  <Banner locale={locale}></Banner>
                </div>
              </div>
            </SearchContext.Provider>
          </IniciativaItemProvider>
        </FilterContextProvider>
      </GlobalContextProvider>
    </>
  );
}

export default (MyApp);
