export const localeTexts = {
  en: {
   gira: {
      programacion: 'Programming',
      calendario: 'Calendar',
      publicaciones: 'Publications',
      tvTour: 'On TV',
      onlineTour: 'Online Tour'
    },
    navbar: {
      nosotros: { label: "About Us" },
      programas: { label: "Programs" },
      calendario: { label: "Calendar" },
      películas: { label: "Films" },
      blog: { label: "Blog" },
      aliados: { label: "Partners" }
    },
    search: {
      viewAllResults: "View all results",
      noResults: "No films containing '{{query}}'",
      tryAgain: "Try another search term",
      placeholder: "Search films by title or director",
      placeHolderPublicaciones:"Search publications by title or author",
    },
    equipo:{
      team:"Team",
      share:"Share",
    },
    initiatives: {
      gira: {
        title: "Documentary Tour",
        description: "Our traveling cinema tour"
      },
      presenta: {
        title: "Presents",
        description: "Collaborative documentary film exhibition circuit."
      },
      masAlla:{
        title:"Beyond",
        description:"Training in Documentary Production",
      },
       ediciones: {
        title:"Editions",
        description:"Works to Expand Film Culture",
      },
    common: {  
      seeAllPrograms: "See all programs",
      now: "Now",
      allPrograms: "All programs",
      programsSection: "By programs"
    },
  },
  breadcrumbs: {
    home: "Home",
    blog: "Blog",
    catalog:"Catalog",
    },
  common:{
    sobre:"About",
    about: "About",
    viewAll: "View all",
    readMore: "Read more",
    back:"Back",
    seeAllPrograms: "See all programs",
    now: "Now",
    allPrograms: "All programs",
    programsSection: "By programs",
    contraloria:"Social Oversight",
    vacantes:"Vacancies",
    prensa:"Press",
    year: "Year",
    country: "Country",
    tourEditions: "Tour Editions",
    sections: "Sections",
    generations: "Generations",
    apEditions: "AP Editions",
    productionYear: "Production Year",
    allPrograms: "All Programs",
    documentaryTour: "Documentary Tour",
    ambulanteBeyond: "Ambulante Beyond",
    searchFilters: "Search filters",
    clear: "Clear",
    applyFilters: "Apply filters",
    filters: "Filters",
    programs: "Programs",
    about: "Sobre",
    viewAll: "Ver todas",
    viewDetails:"View Details",
    viewMoreDetails:" View More Details"
  },
  footer: {
      forYou: "FOR YOU",
      movies: "Films",
      calendar: "Calendar",
      pressCenter: "Press Center",
      programs: "PROGRAMS",
      tour: "Documentary Tour",
      beyond: "Ambulante Beyond",
      presents: "Ambulante Presents",
      editions: "Ambulante Editions",
      information: "INFORMATION",
      directory: "Directory",
      privacy: "Privacy Notice",
      socialControl: "Social Control",
      vacancies: "Vacancies",
      contact: "Contact Us",
      newsletter: "Subscribe to newsletter",
      address: "Zacatecas 142-A, Roma Norte, Cuauhtémoc, 06700, Mexico City",
      copyright: "© 2024 Ambulante. All rights reserved"
    },
    movies: {
      about: "About",
      viewAll: "View all"
    },
    Aliados:{
      navigationAliados:"Sponsors Archive - Ambulante",
      title:" Allies",
      estado:"State",
      programas:"Programs",
      clearFilters: "Clear filters",
    },
     AmbulanteEdiciones: {
      navigationEdiciones: "Ambulante Editions",
      title:"Ambulante Editions",
      libros: "Books",
    },
    AmbulanteMasAlla: {
      navigationMasAlla: " Ambulante Beyond",
      announcementBanner: "Want to be part of the 8th generation of Mobile Beyond?  🎥",
      announcementBannerSub: "🎬 Find out more!",
      proyecta: "Project documentaries of ",
      vimeo: "We recommend watching trailers in our Vimeo channel",
      write: "And write us at",
    },
    AmbulantePresenta: {
      titleSeo: "Ambulante Presents - Ambulante",
      title: "Ambulante Presents",
    },

    atras: "← Back",
    inicio: "Home",
    programas: "Programs",
    por: "By",
    volver: "Back to Top",
    sinopsis: "Synopsis",
    semblanza: "Overview",
    festivales: "Festivals and Awards",
    duracion: "Length",
    paises: "Countries",
    ano: "Year",
    idiomas: "Languages",
    direccion: "Director",
    tematicas: "Themes",
    iniciativas: "Initiatives",
    Mcreditos: "Show Credits",
    Ocreditos: "Close Credits",
    descargas: "Downloads",
    vtrailer: "Watch Trailer",
    nuestrosP: "Our Programs",
    mision: "Our mission is to mobilize spaces for collective encounter and action to build other worlds through documentary. Learn how we do it.",
    expMT: "Discover the films that have been shown at Ambulante",
    expMSub: "Explore by themes, years, countries and more",
    btnExpC: "Explore the catalog",
    gira: "Tour",
    aboutus: "About Us",
    compartir: "Share",
    movieCatalogT: "Explore the titles that have been part of Ambulante.",
    verTodasPeliculas: " View all movies",
    searchResults: "Search results",
    blog:{
      theme:"Theme",
      publicationType:"Type of publication",
      news:"News",
      viewAll:"View all",
      reviews:"Reviews",
      note:"Note",
      clearFilters:"Clear filters",
      viewAllPublications:"View all publications",
      searchResults:"Search Results",
      previous:"Previous",
      next:"Next",
      backToTop:"Back to top",
    },
    filter:{
      searchFilter:"Search filter",
      clear:"Clear",
    },
  },

  "es-MX": {
    gira: {
      programacion: 'Programación',
      calendario: 'Calendario',
      publicaciones: 'Publicaciones',
      tvTour: 'En televisión',
      onlineTour: 'Gira en línea'
    },
    blog:{
      backToTop:"Volver arriba",
      next:"Siguiente",
      previous:"Anterior",
      theme:"Temáticas",
      publicationType:"Tipo de publicación",
      news:"Noticias",
      viewAll:"Ver todas",
      reviews:"Reseñas",
      note:"Nota",
      clearFilters:"Limpiar filtros",
      viewAllPublications:"Ver todas las publicaciones",
      searchResults:"Resultados de búsqueda",
    },
    filter:{
      clear:"Limpiar",
      searchFilter:"Buscar filtros",
    },
    navbar: {
      nosotros: { label: "Nosotros" },
      programas: { label: "Programas" },
      calendario: { label: "Calendario" },
      películas: { label: "Películas" },
      blog: { label: "Blog" },
      aliados: { label: "Aliados" }
    },
    search: {
      viewAllResults: "Ver todos los resultados",
      noResults: "No existen películas que contengan '{{query}}'",
      tryAgain: "Intenta de nuevo con otro nombre",
      placeholder: "Busca películas por título o realizador/a",
      placeHolderPublicaciones:"Buscar publicaciones por título o autor",
    },
    movies: {
      about: "Sobre",
      viewAll: "Ver todas"
    },
    equipo:{
      team:"Equipo",
      share:"Compartir",
    },
      initiatives: {
      gira: {
        title: "Gira de Documentales",
        description: "Nuestra gira de cine itinerante"
      },
      presenta: {
        title: "Presenta",
        description: "Circuito de exhibición de cine documental desde el quehacer colaborativo."
      },
      masAlla:{
        title:"Mas Alla",
        description:"Capacitación en producción documental",
      },
      ediciones: {
        title:"Ediciones",
        description:"Obras para expandir la cultura del cine",

      },
    common: {
      now: "Ahora",
      seeAllPrograms: "Ver todos los programas",
       allPrograms: "Todos los programas",
       programsSection: "Por programas"
    },
  },
  common:{
    viewMoreDetails:"Ver más detalles",
      sobre:"Sobre",
      viewDetails:"Ver detalles",
      prensa:"Prensa",
      now: "Ahora",
      seeAllPrograms: "Ver todos los programas",
      allPrograms: "Todos los programas",
      programsSection: "Por programas",
      readMore: "Leer más",
      back:"Atras",
      contraloria:"Contraloría Social",
      vacantes:"Vacantes",
      year: "Año",
      country: "País",
      tourEditions: "Ediciones Gira",
      sections: "Secciones",
      generations: "Generaciones",
      apEditions: "Ediciones AP",
      productionYear: "Año de producción",
      allPrograms: "Todos los programas",
      documentaryTour: "Gira de Documentales",
      ambulanteBeyond: "Ambulante Más Allá",
      searchFilters: "Buscar filtros",
      clear: "Limpiar",
      applyFilters: "Aplicar filtros",
      filters: "Filtros",
      programs: "Programas"

  },
  breadcrumbs: {
      Home: "Inicio",
      Blog: "Blog",
      catalog:"Catalogo",
    },
    footer: {
      forYou: "PARA TI",
      movies: "Películas",
      calendar: "Calendario",
      pressCenter: "Centro de prensa",
      programs: "PROGRAMAS",
      tour: "Gira Ambulante",
      beyond: "Ambulante Más Allá",
      presents: "Ambulante Presenta",
      editions: "Ambulante Ediciones",
      information: "INFORMACIÓN",
      directory: "Directorio",
      privacy: "Aviso de Privacidad",
      socialControl: "Contraloría Social",
      vacancies: "Vacantes",
      contact: "Contáctanos",
      newsletter: "Suscríbete al newsletter",
      address: "Zacatecas 142-A, Roma Norte, Cuauhtémoc, C.P. 06700, Ciudad de México",
      copyright: "© 2024 Ambulante. Todos los derechos reservados"
    },
    Aliados:{
      navigationAliados:"Patrocinadores Archivo - Ambulante",
      title:"Aliados",
      estado:"Estado",
      programas:"Programas",
      clearFilters: "Limpiar filtros",
    },
    AmbulanteEdiciones: {
      navigationEdiciones: "Ambulante Ediciones",
      title:"Ambulante Ediciones",
      libros: "Libros",
    },
    AmbulanteMasAlla: {
      navigationMasAlla: " Ambulante Más Allá",
      announcementBanner: "¿Quieres ser parte de la 8va generación de Ambulante Más Allá? 🎥",
      announcementBannerSub: "🎬 ¡Conoce más aquí!",
      vimeo: "Te recomendamos ver los tráilers en nuestro canal de Vimeo",
      write: "y escribirnos a",
    },
    AmbulantePresenta: {
      titleSeo: "Ambulante Presenta - Ambulante",
      title: "Ambulante Presenta",
    },
    searchResults: "Resultados de búsqueda",
    verTodasPeliculas: "Ver todas las películas",
    movieCatalogT: "Explora los títulos que han pasado por Ambulante",
    compartir: "Compartir",
    aboutus: "Sobre nosotros",
    gira: "Gira",
    expMT: "Conoce las películas que han pasado por Ambulante",
    expMSub: "Descrubre por temáticas, años, países y más",
    btnExpC: "Explora el catálogo",
    mision: "Nuestra misión es movilizar espacios de encuentro y acción colectiva para construir otros mundos a través del documental. Conoce cómo lo hacemos.",
    nuestrosP: "Nuestros programas",
    atras: "← Atrás",
    inicio: "Inicio",
    programas: "Programas",
    por: "Por",
    volver: "Volver arriba",
    sinopsis: "Sinopsis",
    semblanza: "Semblanza",
    festivales: "Festivales y Premios",
    duracion: "Duracion",
    paises: "Paises",
    ano: "Año",
    idiomas: "Idiomas",
    direccion: "Direccion",
    tematicas: "Tematicas",
    iniciativas: "iniciativas",
    Mcreditos: "Mostrar Créditos",
    Ocreditos: "Ocultar Créditos",
    descargas: "Descargas",
    vtrailer: "Ver Trailer",
  },
};

// Add fallback to Spanish if needed
export const getLocaleTexts = (locale) => {
  return localeTexts[locale] || localeTexts.es;
};
